import * as React from "react";
import * as styles from "./UnorderedList.module.scss";
import { classnames } from "utils";

const UnorderedList = React.forwardRef(
  ({ plain, className, disc = false, children, ...rest }, ref) => {
    return (
      <ul
        ref={ref}
        className={classnames(className, {
          [styles.disc]: !plain,
          [styles.plainList]: plain
        })}
        {...rest}
      >
        {children}
      </ul>
    );
  }
);

function ListItem({ tag = "li", ...rest }) {
  const Tag = tag;
  return <Tag {...rest} />;
}

export { ListItem };

export default UnorderedList;
