exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insights-js": () => import("./../../../src/pages/insights.js" /* webpackChunkName: "component---src-pages-insights-js" */),
  "component---src-pages-main-page-js": () => import("./../../../src/pages/MainPage.js" /* webpackChunkName: "component---src-pages-main-page-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-thinking-js": () => import("./../../../src/pages/thinking.js" /* webpackChunkName: "component---src-pages-thinking-js" */),
  "component---src-pages-thinking-section-js": () => import("./../../../src/pages/thinkingSection.js" /* webpackChunkName: "component---src-pages-thinking-section-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

