// extracted by mini-css-extract-plugin
export var all0 = "_padding-module--all0--06a28";
export var bottom_auto = "_padding-module--bottom_auto--dfd93";
export var bottom_lg = "_padding-module--bottom_lg--bedbe";
export var bottom_md = "_padding-module--bottom_md--a7121";
export var bottom_sm = "_padding-module--bottom_sm--0e1fd";
export var left_0 = "_padding-module--left_0--1156b";
export var left_auto = "_padding-module--left_auto--d9491";
export var left_lg = "_padding-module--left_lg--454fc";
export var left_md = "_padding-module--left_md--b101c";
export var left_sm = "_padding-module--left_sm--e59ca";
export var lg = "_padding-module--lg--e7f30";
export var md = "_padding-module--md--55675";
export var right_auto = "_padding-module--right_auto--723d1";
export var right_lg = "_padding-module--right_lg--17e45";
export var right_md = "_padding-module--right_md--4bb19";
export var right_sm = "_padding-module--right_sm--88329";
export var sides_auto = "_padding-module--sides_auto--93419";
export var sm = "_padding-module--sm--e642c";
export var top_auto = "_padding-module--top_auto--254cb";
export var top_lg = "_padding-module--top_lg--18c00";
export var top_md = "_padding-module--top_md--d524d";
export var top_sm = "_padding-module--top_sm--419fa";
export var xl = "_padding-module--xl--e7c01";