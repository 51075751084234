import * as React from "react";
import { rightArrow } from "./style.module.scss";
import Link from "components/Link";
import { secondary } from "components/text/styles.module.scss";
import RightArrow from "components/RightArrow";

function FooterItem({ tag = "li", url = "/", text = "", onClick, children }) {
  const Tag = tag;
  return (
    <Tag>
      <Link className={secondary} to={url} onClick={onClick}>
        {text || children}
      </Link>
      {!!text && <RightArrow className={rightArrow} />}
    </Tag>
  );
}

export default FooterItem;
