export const footerData = {
  columns: [
    {
      heading: "Ea quis enim",
      items: [
        { text: "Ipsum quis voluptate ", url: "/" },
        { text: "Culpa aute dolor" },
        { text: "Do et non cillum" },
        { text: "Quis eiusmod laboris" },
        { text: "Qui laboris" }
      ]
    },
    {
      heading: "Aliqua irure",
      items: [
        { text: "Et adipisicing" },
        { text: "Amet ex consectetur" },
        { text: "Sit nostrud enim" }
      ]
    }
  ]
};

export const valueProps = [
  {
    heading: "Alignment",
    body: "Fostering a cohesive and collaborative workplace environment."
  },
  {
    heading: "Unity",
    body: "Ensuring that everyone is working towards a common purpose"
  },
  {
    heading: "Vision",
    body: "Connecting aspirations with an organization's forward-looking direction"
  }
];

export const navData = {
  links: [
    { text: "About", to: "/about" },
    {
      text: "Thinking",
      to: "/thinking",
      children: [{ text: "insights", to: "/insights" }]
    },
    { text: "Careers", to: "/careers" },
    { text: "Contact", to: "/contact" }
  ]
};
