import * as React from "react";

export default function useWindowWidth() {
  let init = null;
  if (typeof window !== "undefined") {
    init = window.innerWidth;
  }
  const [width, setWidth] = React.useState(init);

  React.useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return width;
}
