import * as React from "react";
import FooterColumn from "./FooterColumn";
import * as styles from "./style.module.scss";
import SocialLinks from "./SocialLinks";
import EmailIcon from "svg/envelope-icon-outline.svg";
import { flex } from "styles/_flex.module.scss";
import * as margin from "styles/_margin.module.scss";
import static_data from "JSON/static-data.json";
import track from "utils/analytic";
import Link from "components/Link";

function Footer() {
  const footerColumns = static_data.footer.columns;
  const socialLinks = static_data.footer.socialData;

  return (
    <div className={styles.footerContainer}>
      <footer>
        <Column>
          {footerColumns.map(({ heading, items }, index) => (
            <FooterColumn
              key={heading || items[index]?.text}
              heading={heading}
              items={items}
            />
          ))}
        </Column>
        <Column className={styles.email}>
          <h4 className={margin.bottom_md}>EMAIL</h4>
          <div className={flex} style={{ gap: 8 }}>
            <EmailIcon height={18} />
            <Link
              external
              to="mailto:info@co-hesion.com"
              onClick={() => track({ action: "Email footer link clicked" })}
            >
              info@co-hesion.com
            </Link>
          </div>
        </Column>
        <Column>
          <h4 className={margin.bottom_md}>SOCIAL</h4>
          <SocialLinks socialLinks={socialLinks} />
        </Column>
      </footer>
    </div>
  );
}

function Column(props) {
  return <section {...props} />;
}

export default Footer;
