import * as React from "react";
import { Link as GatsbyLink } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import { primary } from "styles/_utils.module.scss";
import { classnames } from "utils";

const Link = ({
  children,
  to,
  className,
  activeClassName,
  partiallyActive,
  external,
  primaryColor,
  target = "__blank",
  ...rest
}) => {
  const internal = /^\/(?!\/)/.test(to);

  return internal ? (
    <GatsbyLink
      className={classnames(className, { [primary]: primaryColor })}
      to={to}
      activeClassName={activeClassName}
      partiallyActive={partiallyActive}
      {...rest}
    >
      {children}
    </GatsbyLink>
  ) : external ? (
    <OutboundLink
      className={classnames(className, { [primary]: primaryColor })}
      href={to}
      {...rest}
    >
      {children}
    </OutboundLink>
  ) : (
    <a
      href={to}
      className={classnames(className, { [primary]: primaryColor })}
      {...rest}
    >
      {children}
    </a>
  );
};

export default Link;
