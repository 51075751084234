import { windowIsDefined } from ".";

function track(data = {}) {
  if (process.env.NODE_ENV === "development") {
    console.log("data: ", data);
    return;
  }
  if (windowIsDefined) {
    return window.gtag("event", "click", { ...data });
  }
}

export default track;
