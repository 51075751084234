import React from "react";
import FooterItem from "./FooterItem";
import * as margin from "styles/_margin.module.scss";
import UnorderedList from "components/UnorderedList";
import track from "utils/analytic";
import * as padding from "styles/_padding.module.scss";

function FooterColumn({ heading, items }) {
  return (
    <div>
      {!!heading && <h4 className={margin.bottom_md}>{heading}</h4>}
      <UnorderedList plain className={padding.left_0}>
        {items.map((props) => (
          <FooterItem
            key={props.text}
            onClick={() =>
              track({ action: `${props.text} footer link clicked` })
            }
            {...props}
          />
        ))}
      </UnorderedList>
    </div>
  );
}

export default FooterColumn;
