// extracted by mini-css-extract-plugin
export var absolute = "_utils-module--absolute--eae4b";
export var accent = "_utils-module--accent--25e74";
export var capitalize = "_utils-module--capitalize--f27e5";
export var defaultLineHeight = "_utils-module--defaultLineHeight--83697";
export var fit = "_utils-module--fit--b2a30";
export var floatRight = "_utils-module--floatRight--40545";
export var fontWeightLight = "_utils-module--fontWeightLight--68583";
export var fontWeightRegular = "_utils-module--fontWeightRegular--35be1";
export var gray = "_utils-module--gray--18bc5";
export var opacity0 = "_utils-module--opacity0--2ab0f";
export var primary = "_utils-module--primary--77382";
export var relative = "_utils-module--relative--82caa";
export var textCenter = "_utils-module--textCenter--8569a";
export var textDecorationNone = "_utils-module--textDecorationNone--04033";
export var textRight = "_utils-module--textRight--1c4d4";
export var uppercase = "_utils-module--uppercase--da5f4";
export var white = "_utils-module--white--4b060";
export var width100 = "_utils-module--width100--9ee59";